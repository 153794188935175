.nav-logo {
  font-size: 30px;
  font-weight: 600;
}
.nav-items {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #0e0e0ecc;
  display: block;
  line-height: 102px;
  padding: 0 16px;
}
.nav-active {
  color: rgb(1, 214, 163);
}
.hero {
  background: url("./assets/images/slides/slider-mainbg-001.jpg") no-repeat
    center center/cover;
  height: 750px;
  position: relative;
  color: #fff;
}

.hero .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 5px;
  margin-left: 5%;
  font-size: 70px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
}

.hero .icon-text {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  margin-left: 6px;
}

.hero .icon-text img {
  width: 113px;
  height: 45px;
  vertical-align: middle;
  margin-right: 10px;
}

.hero .icon-text span {
  line-height: 16px;
  font-size: 16px;
  font-weight: 400;
  margin: 5px 0 0 0;
  text-transform: uppercase;
}

.hero .subtext {
  line-height: 25px;
  font-size: 16px;
  font-weight: 300;
  margin-top: 15px;
}

.hero .reachus-btn {
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #fff;
  font-size: 13px;
  font-weight: 600;
  color: #fff;
  padding: 8px 14px;
  height: auto;
  width: fit-content;
  line-height: 25px;
  margin-top: 15px;
}

.bg-img1 {
  background: url("./assets/images/bg-image/row-bgimage-1.jpg") no-repeat center
    center/cover;
}

.bg-img5 {
  background: url("./assets/images/bg-image/row-bgimage-5.jpg") no-repeat center
    center/cover;
}

.bg-img6 {
  background: url("./assets/images/bg-image/row-bgimage-6.jpg") no-repeat center
    center/cover;
}

.testimonial-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.testimonial-control {
  display: flex;
  justify-content: space-between;
  width: 50px;
}

.testimonial-control .fa {
  font-size: 19px;
  font-weight: 500;
}

.clients {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.m-18 {
  margin: 20px auto;
}
.show-content {
  display: block;
  margin-top: 15px;
}

.minus-icon{
  content: "\f068";
}

.img-fluid-0 {
  height: 425px;
  width: 405px;
}
.img-fluid-1 {
  height: 240px;
  width: 350px;
}

.img-fluid-2 {
  height: 240px;
  width: 100%;
}

.pt-15 {
  padding-top: 15px;
}

.event-title {
  color: #00000059 !important;
}

.pointer-cursor {
  cursor: pointer;
}

.category {
  padding-top: 4px;
  line-height: 14px;
}

.ttm-row-1 {
  padding: 40px 0;
}

.m-top40 {
  margin-top: 40px;
}

.home-link {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .hero {
    height: 440px;
  }

  .hero .content {
    font-size: 33px;
    line-height: 33px;
    text-align: center;
  }

  .hero .icon-text img,
  .hero .subtext {
    display: none;
  }

  .hero .icon-text {
    justify-content: center;
  }

  .hero .icon-text span {
    line-height: 11px;
    font-size: 11px;
    font-weight: 300;
  }

  .hero .reachus-btn {
    font-size: 10px;
    padding: 6px 12px;
  }
  .nav-items {
    line-height: 18px;
    padding: 15px 0;
  }
}
